import StoreIcon from "@mui/icons-material/Store";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAuditByResellerId } from "../../../actions/auditActions";
import { getAllLicensesByResellerId } from "../../../actions/licensesActions";
import { getAllSubReseller } from "../../../actions/resellerActions";
import { getUsageLogs } from "../../../actions/usageLogActions";
import "./Dashboard.scss";

const Dashboard = () => {
    const [licenses, setLicenses] = useState([]);
    const [resellers, setResellers] = useState([]);
    const [usageLogs, setUsageLogs] = useState([]);
    const [audit, setAudit] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const identity = useSelector((state) => state.identity);

    useEffect(() => {
        if (identity?.user?.resellerId) {
            loadData();
        }
    }, [identity]);

    const loadData = async () => {
        const licenseData = await dispatch(getAllLicensesByResellerId(identity.user.resellerId));
        setLicenses(licenseData);

        const resellerData = await dispatch(getAllSubReseller(identity.user.resellerId));
        setResellers(resellerData);

        const usageLogData = await dispatch(getUsageLogs({ skip: 0, take: 10 }, identity.user.resellerId));
        setUsageLogs(usageLogData.data);

        const auditData = await dispatch(getAuditByResellerId({ skip: 0, take: 10 }, identity.user.resellerId));
        setAudit(auditData.data);
    };

    const licenseColumns = [
        { field: "LicenseNumber", headerName: "License Number", flex: 1 },
        { field: "StoreName", headerName: "Store Name", flex: 1 },
        { field: "Owner", headerName: "Owner", flex: 1 },
    ];

    const resellerColumns = [
        { field: "resellerName", headerName: "Name", flex: 1 },
        { field: "licenseName", headerName: "License", flex: 1 },
    ];

    const usageLogColumns = [
        { field: "Name", headerName: "Name", flex: 1 },
        { field: "ClientName", headerName: "Client", flex: 1 },
        {
            field: "timeStamp",
            headerName: "Time",
            type: "dateTime",
            flex: 1,
            valueGetter: (params) => new Date(params.value),
        },
    ];

    const auditColumns = [
        { field: "name", headerName: "Name", flex: 1 },
        {
            field: "timeStamp",
            headerName: "Time",
            type: "dateTime",
            flex: 1,
            valueGetter: (params) => new Date(params.value),
        },
        { field: "tableName", headerName: "Table", flex: 1 },
    ];

    if (!identity?.user) return null;

    return (
        <Box id="dashboard" sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <VpnKeyIcon sx={{ mr: 1 }} /> Lizenzen
                        </Typography>
                        <DataGrid
                            rows={licenses}
                            columns={licenseColumns}
                            getRowId={(row) => row.LicenseId}
                            onRowClick={(params) => {
                                navigate(`/lizenzen/${params.row.LicenseId}`);
                            }}
                            autoHeight
                            pageSize={5}
                            sx={{ mb: 2 }}
                        />
                        <Link to="/lizenzen">zu den Lizenzen</Link>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <StoreIcon sx={{ mr: 1 }} /> Reseller
                        </Typography>
                        <DataGrid
                            rows={resellers}
                            columns={resellerColumns}
                            getRowId={(row) => row.resellerId}
                            autoHeight
                            pageSize={5}
                            sx={{ mb: 2 }}
                        />
                        <Link to="/resellers">zu den Resellern</Link>
                    </Paper>
                </Grid>

                {/*  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <LockOpenIcon sx={{ mr: 1 }} /> UsageLogs
                        </Typography>
                        <DataGrid
                            rows={usageLogs}
                            columns={usageLogColumns}
                            getRowId={(row) => row.UsageLogId}
                            autoHeight
                            pageSize={5}
                            sx={{ mb: 2 }}
                        />
                        <Link to="/usageLogs">zu den UsageLogs</Link>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <AssignmentIcon sx={{ mr: 1 }} /> Audit
                        </Typography>
                        <DataGrid
                            rows={audit}
                            columns={auditColumns}
                            getRowId={(row) => row.Id}
                            autoHeight
                            pageSize={5}
                            sx={{ mb: 2 }}
                        />
                        <Link to="/audit">zum Audit</Link>
                    </Paper>
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default Dashboard;
