import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllSubReseller(resellerId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/getAllSubReseller", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function removeReseller(resellerId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/removeReseller", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateResellerName(resellerId, resellerName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ resellerId, resellerName }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/updateResellerName", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateResellerLicenses(resellerId, licenseIds) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ resellerId, licenseIds }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/updateResellerLicenses", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertReseller(licenseIds, resellerName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ licenseIds, resellerName }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/insertReseller", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
