import React, { Component } from "react";

export default class RegistrationHeader extends Component {
    render() {
        return (
            <header id="header" className={"not-logged-in"}>
                <div id="logo">BIOS Registrierung</div>
            </header>
        );
    }
}
