import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import License from "./License";
import Sync from "./sync/Sync";
import Webshops from "./webShops/WebShops";

const LicenseTabsManagement = () => {
    const { id } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = [
        { label: "Lizenz", content: <License licenseId={id} /> },
        { label: "Online-Shops", content: <Webshops licenseId={id} /> },
        // { label: "Module", content: <Modules licenseId={id} /> },
        //{ label: "Api Key", content: <ApiKey licenseId={id} /> },
        { label: "Sync", content: <Sync licenseId={id} /> },
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{ p: 2 }}>{tabs[selectedTab].content}</Box>
        </Box>
    );
};

export default LicenseTabsManagement;
