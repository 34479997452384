import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteUser, getUsersByResellerId } from "../../../../actions/userActions";
import store from "../../../../store";
import PopupAddUser from "./PopupAddUser";
import PopupEditUser from "./PopupEditUser";
import "./Users.scss";

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            resellerId: 0,
            selectedUser: undefined,
            showEditPopup: false,
            showAddPopup: false,
            searchText: "",
        };

        this.handleUsersInit = this.handleUsersInit.bind(this);
        this.unselectUser = this.unselectUser.bind(this);
        this.reloadUsers = this.reloadUsers.bind(this);
        this.toggleAddPopup = this.toggleAddPopup.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            resellerId: id,
        });
        this.handleUsersInit();
    }

    reloadUsers() {
        const { id } = this.props.match.params;
        store.dispatch(getUsersByResellerId(id)).then((json) => {
            this.setState({ users: json });
        });
    }

    toggleAddPopup() {
        this.setState({ showAddPopup: !this.state.showAddPopup });
    }

    toggleDeletePopup() {
        this.setState({ showDeletePopup: !this.state.showDeletePopup });
    }

    render() {
        const filteredUsers = this.state.users.filter(
            (user) =>
                user.UserName.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                user.FirstName.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                user.LastName.toLowerCase().includes(this.state.searchText.toLowerCase())
        );

        return (
            <div className="users">
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h1>Benutzer verwalten</h1>
                        <Button onClick={this.toggleAddPopup} variant="contained" sx={{ height: 40 }}>
                            Benutzer hinzufügen
                        </Button>
                    </div>

                    <TextField
                        placeholder="Suche..."
                        variant="outlined"
                        size="small"
                        sx={{ mb: 2, width: 240 }}
                        value={this.state.searchText}
                        onChange={(e) => this.setState({ searchText: e.target.value })}
                    />

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Benutzername</TableCell>
                                    <TableCell>Vorname</TableCell>
                                    <TableCell>Nachname</TableCell>
                                    <TableCell align="right">Aktionen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.map((user) => (
                                    <TableRow
                                        key={user.Id}
                                        sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}
                                    >
                                        <TableCell>{user.UserName}</TableCell>
                                        <TableCell>{user.FirstName}</TableCell>
                                        <TableCell>{user.LastName}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() =>
                                                    this.setState({ selectedUser: user, showEditPopup: true })
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    this.setState({ selectedUser: user, showDeletePopup: true })
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <PopupEditUser
                        showEditPopup={this.state.showEditPopup}
                        userData={this.state.selectedUser}
                        hide={this.unselectUser}
                        reloadUsers={this.reloadUsers}
                    />
                    <PopupAddUser
                        showAddPopup={this.state.showAddPopup}
                        hide={this.toggleAddPopup}
                        reloadUsers={this.reloadUsers}
                        resellerId={this.state.resellerId}
                    />
                    <Dialog open={this.state.showDeletePopup} onClose={this.toggleDeletePopup}>
                        <DialogTitle>Benutzer löschen</DialogTitle>
                        <DialogContent>
                            <p>
                                Soll der Benutzer {this.state.selectedUser ? this.state.selectedUser.FirstName : ""}{" "}
                                {this.state.selectedUser ? this.state.selectedUser.LastName : ""} wirklich gelöscht
                                werden?
                            </p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.toggleDeletePopup}>Abbrechen</Button>
                            <Button onClick={() => this.handleDeleteUser(this.state.selectedUser)} color="error">
                                Löschen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }

    unselectUser() {
        this.setState({
            showEditPopup: false,
        });
    }

    prepareUserData(userData) {
        if (userData.UserRoles.length > 0) {
            var roles = [];
            if (userData.UserRoles[0].Role) {
                roles = userData.UserRoles.map((userRole) => {
                    return userRole.Role.Id;
                });
            } else {
                roles = userData.UserRoles;
            }

            userData.UserRoles = roles;
        }

        return userData;
    }

    handleUsersInit() {
        const { id } = this.props.match.params;
        store.dispatch(getUsersByResellerId(id)).then((json) => {
            this.setState({ users: json });
        });
    }

    handleDeleteUser(e) {
        const { id } = this.props.match.params;
        if (e.Id === this.props.identity.user.Id) {
            // Using browser alert instead of devextreme notify
            alert("Löschen nicht möglich. Sie können sich nicht selbst löschen");
            return;
        }
        store.dispatch(deleteUser(e.Id)).then((json) => {
            this.setState({
                showDeletePopup: false,
            });
            store.dispatch(getUsersByResellerId(id)).then((json) => {
                this.setState({ users: json });
            });
        });
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Users);
