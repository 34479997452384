import { Box, Button, Checkbox, Grid, TextField } from "@mui/material";
import React, { Component } from "react";
import { deployShop } from "../../../../actions/shopActions";
import { getWebShopByWebShopsId } from "../../../../actions/webShopActions";
import store from "../../../../store";
import "./WebShops.scss";

class Webshop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webShop: undefined,
            webShopId: this.props.match.params.id,
            deployStatus: 0,
        };

        this.onStoreNameChanged = this.onStoreNameChanged.bind(this);
        this.onLicenseChanged = this.onLicenseChanged.bind(this);
        this.deployButton = this.deployButton.bind(this);
        this.getDeployStatus = this.getDeployStatus.bind(this);
        this.deployShop = this.deployShop.bind(this);

        this.validationRules = {
            name: [{ type: "required", message: "Name muss angegeben werden!" }],
            webShopType: [{ type: "required", message: "Der WebShop-Typ muss angegeben werden!" }],
            validityDate: [{ type: "required", message: "Das Ablaufdatum muss angegeben werden!" }],
            username: [{ type: "required", message: "Der Benutzername muss angegeben werden!" }],
            password: [{ type: "required", message: "Das Passwort muss angegeben werden!" }],
        };
    }

    componentDidMount() {
        store.dispatch(getWebShopByWebShopsId(this.state.webShopId)).then((json) => {
            this.setState({ webShop: json });
        });
    }

    onStoreNameChanged(e) {}

    onLicenseChanged(e) {}

    deployButton() {
        if (this.state.deployStatus === 0) {
            return (
                <Box sx={{ display: "grid", marginTop: 2 }}>
                    <Button variant="contained" onClick={this.deployShop}>
                        Deploy
                    </Button>
                </Box>
            );
        } else {
            return (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>Deploy-Status: {this.state.deployStatusString}</div>
                    <Button variant="contained" onClick={this.getDeployStatus}>
                        Status aktualisieren
                    </Button>
                </Box>
            );
        }
    }

    getDeployStatus() {}

    deployShop() {
        store.dispatch(deployShop(this.state.licenseId, this.state.webShopId));
    }

    render() {
        const { webShop, loading } = this.state;

        return (
            <div className="webshops">
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={webShop?.StoreName || ""}
                                onChange={this.onStoreNameChanged}
                                required
                                disabled={loading}
                                error={!webShop?.StoreName}
                                helperText={!webShop?.StoreName && "Name muss angegeben werden!"}
                            />
                            <TextField
                                fullWidth
                                label="Url"
                                value={webShop?.Url || ""}
                                onChange={this.onLicenseChanged}
                                disabled={loading}
                            />
                            <TextField
                                fullWidth
                                label="Webshop-Typ"
                                value={webShop?.WebShopType || ""}
                                disabled
                                required
                            />
                            <TextField
                                fullWidth
                                label="Webshop-Version"
                                value={webShop?.WebshopVersion || ""}
                                disabled
                            />
                            <TextField
                                fullWidth
                                label="Webshop-Versionsnummer"
                                value={webShop?.WebshopVersionsnummer || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={webShop?.Revoked || false}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <span>Lizenz gesperrt</span>
                            </Box>
                            {webShop?.WebShopType === 70 && this.deployButton()}
                        </Grid>
                    </Grid>

                    {webShop?.WebShopType === 70 && (
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Titel"
                                    value={webShop?.title || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Benutzername"
                                    value={webShop?.Username || ""}
                                    onChange={this.onLicenseChanged}
                                    required
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Passwort"
                                    value={webShop?.Password || ""}
                                    onChange={this.onLicenseChanged}
                                    required
                                    type="password"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Gültig bis"
                                    value={webShop?.Validitydate || ""}
                                    onChange={this.onLicenseChanged}
                                    required
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Server-Url"
                                    value={webShop?.ServerUrl || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Websocket-Url"
                                    value={webShop?.WebSocketUrl || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="AppClient-ID"
                                    value={webShop?.AppClientID || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Custom Domain"
                                    value={webShop?.customDomain || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Azure B2C User Pool ID"
                                    value={webShop?.azureB2CPoolId || ""}
                                    onChange={this.onLicenseChanged}
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </div>
        );
    }
}

export default Webshop;
