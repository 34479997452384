import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Snackbar,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserData, updateUserPassword, updateUserRole } from "../../../../actions/userActions";
import store from "../../../../store";
import "./PopupEditUser.scss";

const tabPanels = [
    {
        id: 0,
        text: "Login Daten",
        content: "loginData",
    },
    {
        id: 1,
        text: "Persönliche Daten",
        content: "userData",
    },
];

class PopupEditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            currentTab: 0,
            currentContent: tabPanels[0].content,
            userData: undefined,
            password: "",
            newPassword: "",
            newPasswordConfirm: "",
            showOldPassword: false,
            showNewPassword: false,
            showNewPasswordConfirm: false,
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarSeverity: "success",
        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.updateLoginData = this.updateLoginData.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.passwordComparison = this.passwordComparison.bind(this);
        this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onNewPasswordConfirmChanged = this.onNewPasswordConfirmChanged.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.userData) {
            if (state.userData === undefined) {
                state.userData = props.userData;
            } else {
                if (state.userData.Email !== props.userData.Email) {
                    state.userData = { ...props.userData, newPassword: "", newPasswordConfirm: "" };
                }
            }
        }
        return state;
    }

    passwordComparison() {
        return this.state.newPassword;
    }

    onOldPasswordChanged(e) {
        this.setState({
            oldPassword: e.target.value,
        });
    }

    onNewPasswordChanged(e) {
        this.setState({
            newPassword: e.target.value,
        });
    }

    onNewPasswordConfirmChanged(e) {
        this.setState({
            newPasswordConfirm: e.target.value,
        });
    }

    handleSnackbarClose() {
        this.setState({
            snackbarOpen: false,
        });
    }

    render() {
        if (this.props.userData) {
            var firstName = this.props.userData.FirstName ? this.props.userData.FirstName : "";
            var lastName = this.props.userData.LastName ? this.props.userData.LastName : "";
            var fullName = "";
            firstName === "" && lastName === "" ? (fullName = "kein Name") : (fullName = firstName + " " + lastName);
        }
        return (
            <Dialog open={this.props.showEditPopup} onClose={this.props.hide} fullWidth maxWidth="sm">
                <DialogTitle>{fullName}</DialogTitle>
                <DialogContent>
                    {this.state.userData && (
                        <div>
                            <Tabs value={this.state.currentTab} onChange={this.handleTabChange}>
                                {tabPanels.map((tab) => (
                                    <Tab key={tab.id} label={tab.text} />
                                ))}
                            </Tabs>
                            <div className="popupEditUserContent" style={{ marginTop: "15px" }}>
                                {this.state.isLoading && <CircularProgress />}
                                {this.state.currentContent === "userData" && (
                                    <div>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Vorname"
                                            value={this.state.userData.FirstName || ""}
                                            onChange={(e) =>
                                                this.updateUserData({ dataField: "FirstName", value: e.target.value })
                                            }
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Nachname"
                                            value={this.state.userData.LastName || ""}
                                            onChange={(e) =>
                                                this.updateUserData({ dataField: "LastName", value: e.target.value })
                                            }
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="E-Mail"
                                            value={this.state.userData.Email || ""}
                                            onChange={(e) =>
                                                this.updateUserData({ dataField: "Email", value: e.target.value })
                                            }
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Telefonnummer"
                                            value={this.state.userData.PhoneNumber || ""}
                                            onChange={(e) =>
                                                this.updateUserData({ dataField: "PhoneNumber", value: e.target.value })
                                            }
                                        />
                                    </div>
                                )}
                                {this.state.currentContent === "loginData" && (
                                    <div className="loginDataForm">
                                        <div className="dx-fieldset">
                                            <h3>Login Daten</h3>
                                            {this.props.userData.Id === this.props.identity.user.Id && (
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Altes Passwort"
                                                    type={this.state.showOldPassword ? "text" : "password"}
                                                    value={this.state.oldPassword || ""}
                                                    onChange={this.onOldPasswordChanged}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <Button
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showOldPassword: !this.state.showOldPassword,
                                                                    })
                                                                }
                                                            >
                                                                {this.state.showOldPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </Button>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Neues Passwort"
                                                type={this.state.showNewPassword ? "text" : "password"}
                                                value={this.state.newPassword || ""}
                                                onChange={this.onNewPasswordChanged}
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    showNewPassword: !this.state.showNewPassword,
                                                                })
                                                            }
                                                        >
                                                            {this.state.showNewPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </Button>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Neues Passwort bestätigen"
                                                type={this.state.showNewPasswordConfirm ? "text" : "password"}
                                                value={this.state.newPasswordConfirm || ""}
                                                onChange={this.onNewPasswordConfirmChanged}
                                                required
                                                error={this.state.newPassword !== this.state.newPasswordConfirm}
                                                helperText={
                                                    this.state.newPassword !== this.state.newPasswordConfirm
                                                        ? "Passwörter stimmen nicht überein"
                                                        : ""
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    showNewPasswordConfirm:
                                                                        !this.state.showNewPasswordConfirm,
                                                                })
                                                            }
                                                        >
                                                            {this.state.showNewPasswordConfirm ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </Button>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <p className="passwordHint">
                                            {
                                                "Wichtig: Passwörter müssen mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten!"
                                            }
                                        </p>
                                        <div className="changePasswordButtonDiv">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="changePasswordButton"
                                                disabled={
                                                    this.state.newPassword !== this.state.newPasswordConfirm ||
                                                    (this.state.oldPassword === "" &&
                                                        this.props.userData.Id === this.props.identity.user.Id) ||
                                                    this.state.newPasswordConfirm === "" ||
                                                    this.state.newPassword === ""
                                                }
                                                onClick={this.changePassword}
                                            >
                                                Passwort ändern
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <Snackbar
                        open={this.state.snackbarOpen}
                        autoHideDuration={6000}
                        onClose={this.handleSnackbarClose}
                        message={this.state.snackbarMessage}
                        severity={this.state.snackbarSeverity}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    handleTabChange(event, newValue) {
        this.setState({
            currentTab: newValue,
            currentContent: tabPanels[newValue].content,
        });
    }

    updateUserData(e) {
        this.setState({ isLoading: true });

        store.dispatch(updateUserData({ ...this.state.userData, [e.dataField]: e.value })).then((result) => {
            this.setState({
                snackbarOpen: true,
                snackbarMessage: "Änderungen erfolgreich",
                snackbarSeverity: "success",
                isLoading: false,
                ...this.state.userData,
                [e.dataField]: e.value,
            });
            this.props.reloadUsers();
        });
    }

    changePassword() {
        var oldPassword = this.state.oldPassword;
        var newPasswordConfirm = this.state.newPasswordConfirm;

        if (this.state.isLoading === false) {
            this.setState({ isLoading: true });
            store
                .dispatch(
                    updateUserPassword({
                        newPassword: newPasswordConfirm,
                        Id: this.state.userData.Id,
                        oldPassword: oldPassword,
                    })
                )
                .then((result) => {
                    if (result.status === 200) {
                        this.setState({
                            snackbarOpen: true,
                            snackbarMessage: "Passwort erfolgreich geändert",
                            snackbarSeverity: "success",
                            isLoading: false,
                            newPassword: "",
                            oldPassword: "",
                            newPasswordConfirm: "",
                        });
                    } else {
                        this.setState({
                            snackbarOpen: true,
                            snackbarMessage: result.errorMessage,
                            snackbarSeverity: "error",
                            isLoading: false,
                        });
                    }
                });
        }
    }

    updateLoginData(e) {
        if (e.dataField === "UserRoles") {
            this.setState({ isLoading: true });

            store.dispatch(updateUserRole(this.state.userData.Id, e.value)).then((result) => {
                this.setState({
                    snackbarOpen: true,
                    snackbarMessage: "Passwort erfolgreich geändert",
                    snackbarSeverity: "success",
                    isLoading: false,
                    ...this.state.userData,
                    [e.dataField]: e.value,
                });
            });
        }

        if (e.dataField === "oldPassword" || e.dataField === "newPassword" || e.dataField === "newPasswordConfirm") {
            var oldPassword = this.state.userData.oldPassword;
            var newPassword = this.state.userData.newPassword;
            var newPasswordConfirm = this.state.userData.newPasswordConfirm;

            if (newPassword !== undefined && newPasswordConfirm !== undefined) {
                if (newPassword === newPasswordConfirm) {
                    if (this.state.isLoading === false) {
                        this.setState({ isLoading: true });
                        store
                            .dispatch(
                                updateUserPassword({
                                    newPassword: newPasswordConfirm,
                                    Id: this.state.userData.Id,
                                    oldPassword: oldPassword,
                                })
                            )
                            .then((result) => {
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMessage: "Passwort erfolgreich geändert",
                                    snackbarSeverity: "success",
                                    isLoading: false,
                                    userData: {
                                        ...this.state.userData,
                                        newPassword: undefined,
                                        oldPassword: undefined,
                                        newPasswordConfirm: undefined,
                                    },
                                });
                            });
                    }
                } else {
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: "Passwörter stimmen nicht überein",
                        snackbarSeverity: "error",
                    });
                }
            }
        }
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(PopupEditUser);
