import { setUser } from "../actions/identityActions";
import store from "../store";
import { history } from "./history";

export function authHeader() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            "Content-Type": "application/json",
            Authorization: user.Token,
        };
    }
    return { "Content-Type": "application/json" };
}

export function authHeaderUpload() {
    // return authorization header with jwt token if logged in
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
        return {
            Authorization: user.Token,
        };
    }
    return {};
}

export function handleResponse(response) {
    if (200 <= response.status && response.status < 400) {
        return response
            .clone()
            .text()
            .then(function (text) {
                try {
                    let responseParsed = JSON.parse(text);
                    return responseParsed;
                } catch {
                    return response;
                }
            });
    } else {
        if (response.status === 401) {
            store.dispatch(
                setUser({
                    UserName: null,
                    Id: null,
                    Token: null,
                    Employee: null,
                })
            );
            history.push("/login");
            return response;
        } else {
            response.json();
            return response;
        }
    }
}

export function getToast(msg, type) {
    var time = 1000;
    switch (type) {
        case "success":
            break;
        case "error":
            time = 5000;
            break;
        case "warning":
            time = 4000;
            break;
        case "info":
            time = 4000;
            break;
        default:
            break;
    }
    var toastSettings = {
        message: msg,
        type: type,
        displayTime: time,
        position: {
            my: "Top",
            at: "Top",
        },
        //width: function () { return $(window).width(); }
    };
    return toastSettings;
}
