import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getWebShopsByLicenseId(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Number(id)),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/getWebShopsByLicenseId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getWebShopByWebShopsId(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Number(id)),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/getWebShopByWebShopsId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getWebShopTypes() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/getWebShopTypes", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateWebShop(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/updateWebShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertWebShop(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/insertWebShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getTakeAwaysByLicenseId(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Number(id)),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/getTakeAwaysByLicenseId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateTakeAway(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/updateTakeAway", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteTakeAway(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/deleteTakeAway", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertTakeAway(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/WebShops/insertTakeAway", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
