import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import StopIcon from "@mui/icons-material/Stop";
import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { Component } from "react";
import {
    restartContainerInstance,
    startContainerInstance,
    stopContainerInstance,
} from "../../../actions/containerActions";
import store from "../../../store";
import Container from "./Container";

export default class ContainerInstance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            containersVisible: true,
            isLoading: false,
        };

        this.toggleContainers = this.toggleContainers.bind(this);
        this.stopContainerInstance = this.stopContainerInstance.bind(this);
        this.startContainerInstance = this.startContainerInstance.bind(this);
        this.restartContainerInstance = this.restartContainerInstance.bind(this);
    }

    toggleContainers() {
        this.setState({
            containersVisible: !this.state.containersVisible,
        });
    }

    stopContainerInstance() {
        this.setState({
            isLoading: true,
        });
        store.dispatch(stopContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) => {
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false,
            });
        });
    }

    startContainerInstance() {
        this.setState({
            isLoading: true,
        });
        store.dispatch(startContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) => {
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false,
            });
        });
    }

    restartContainerInstance() {
        this.setState({
            isLoading: true,
        });
        store.dispatch(restartContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) => {
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false,
            });
        });
    }

    render() {
        return (
            <Box sx={{ maxWidth: "1000px", border: 1, borderColor: "grey.300" }}>
                <Box sx={{ display: "flex", m: 0.5, alignItems: "center" }}>
                    <IconButton onClick={this.toggleContainers}>
                        {this.state.containersVisible ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            ml: 1,
                        }}
                    >
                        Instanz: {this.props.containerInstance[0].containerGroupName}
                    </Box>
                    {(this.props.containerInstance[0].deployStatus === 5 ||
                        this.props.containerInstance[0].deployStatus === 98) &&
                        !this.state.isLoading && (
                            <IconButton
                                title={this.props.containerInstance[0].deployStatus === 5 ? "Stoppen" : "Starten"}
                                onClick={
                                    this.props.containerInstance[0].deployStatus === 5
                                        ? this.stopContainerInstance
                                        : this.startContainerInstance
                                }
                            >
                                {this.props.containerInstance[0].deployStatus === 5 ? <StopIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        )}
                    {this.props.containerInstance[0].deployStatus === 5 && !this.state.isLoading && (
                        <IconButton sx={{ ml: 1 }} title="Neustarten" onClick={this.restartContainerInstance}>
                            <RestartAltIcon />
                        </IconButton>
                    )}
                    {this.state.isLoading && <CircularProgress size={24} sx={{ m: 1 }} />}
                </Box>
                {this.state.containersVisible && (
                    <Box>
                        {this.props.containerInstance.map((container, index) => {
                            return <Container key={index} container={container} />;
                        })}
                    </Box>
                )}
            </Box>
        );
    }
}
