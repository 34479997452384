import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGOUT_USER } from "../../../actions/identityActions";
import "./Header.scss";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const identity = useSelector((state) => state.identity);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleResetApp = () => {
        dispatch({ type: "APP_RESET" });
    };

    const handleLogout = () => {
        dispatch({ type: LOGOUT_USER });
        navigate("/login");
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <header id="header" className={identity.user.Token ? "logged-in" : "not-logged-in"}>
            <div id="logo">Lizenzserver</div>
            <div id="userMenu">
                {identity?.user?.Username && (
                    <>
                        <Button
                            onClick={handleClick}
                            color="inherit"
                            aria-controls={open ? "user-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                        >
                            {identity.user.Username}
                        </Button>
                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </>
                )}
            </div>
            <div id="reloadButton">
                <FontAwesomeIcon icon="undo" onClick={handleResetApp} />
            </div>
        </header>
    );
};

export default Header;
