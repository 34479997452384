import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllSubLicenses } from "../../../actions/licensesActions";
import {
    getAllSubReseller,
    insertReseller,
    removeReseller,
    updateResellerName,
} from "../../../actions/resellerActions";
import "./Resellers.scss";

const Resellers = () => {
    const [resellers, setResellers] = useState([]);
    const [allAvailableLicenses, setAllAvailableLicenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const identity = useSelector((state) => state.identity);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        try {
            const [resellerData, licenseData] = await Promise.all([
                dispatch(getAllSubReseller(identity.user.resellerId)),
                dispatch(getAllSubLicenses(identity.user.resellerId)),
            ]);
            setResellers(resellerData);
            setAllAvailableLicenses(licenseData);
        } catch (error) {
            console.error("Error loading data:", error);
        }
        setLoading(false);
    };

    const handleUpdateName = async (value) => {
        setLoading(true);
        try {
            await dispatch(updateResellerName(value.id, value.value));
            await loadData();
        } catch (error) {
            console.error("Error updating name:", error);
        }
        setLoading(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await dispatch(removeReseller(id));
            await loadData();
        } catch (error) {
            console.error("Error deleting reseller:", error);
        }
        setLoading(false);
    };
    const columns = [
        { field: "resellerId", headerName: "ID", width: 70 },
        {
            field: "resellerName",
            headerName: "Name",
            flex: 1,
            editable: true,
        },
        {
            field: "licenseName",
            headerName: "Lizens-Name",
            flex: 1,
        },
        {
            field: "actions",
            headerName: "Benutzer",
            width: 120,
            renderCell: (value) => {
                if (!value?.row?.resellerId) return null;
                return (
                    <Button component={Link} to={`/resellers/${value.row.resellerId}`} variant="text" size="small">
                        Benutzer
                    </Button>
                );
            },
        },
    ];

    return (
        <Box className="resellers" sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Reseller verwalten
            </Typography>

            <DataGrid
                rows={resellers}
                columns={columns}
                getRowId={(row) => row.resellerId}
                loading={loading}
                autoHeight
                disableRowSelectionOnClick
                processRowUpdate={async (newRow, oldRow) => {
                    if (newRow.resellerName !== oldRow.resellerName) {
                        await handleUpdateName({
                            id: newRow.resellerId,
                            value: newRow.resellerName,
                        });
                    }
                    return newRow;
                }}
                onProcessRowUpdateError={(error) => {
                    console.error("Error updating row:", error);
                }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(insertReseller([], "Neuer Reseller"));
                        loadData();
                    }}
                >
                    Reseller hinzufügen
                </Button>
            </Box>
        </Box>
    );
};

export default Resellers;
