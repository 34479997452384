import React from "react";
import "./Registration.scss";
import RegistrationForm from "./registrationForm/RegistrationForm";
import RegistrationHeader from "./RegistrationHeader";

const Registration = () => {
    return (
        <div className="registration">
            <RegistrationHeader />
            <div className="registrationBox" style={{ marginTop: 50 }}>
                <RegistrationForm />
            </div>
        </div>
    );
};

export default Registration;
