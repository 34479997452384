import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function updateShop(shop) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(shop),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/shops/update", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function createShop(shop){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(shop),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/shops/create", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };

}

export function deployShop(licenseId, shopId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({licenseId: licenseId, licmanUrl: window.location.origin, shopId: shopId}),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Shops/deployShop", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}



export function getAvailableShopVersions(shopKind) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(shopKind),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Shops/getAvailableShopVersions", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}