import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsageLogs } from "../../../actions/usageLogActions";
import "./UsageLogs.scss";

const UsageLogs = () => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const dispatch = useDispatch();
    const identity = useSelector((state) => state.identity);

    const columns = [
        { field: "Name", headerName: "Lizenzname", flex: 1 },
        { field: "LicenseNumber", headerName: "Lizenznummer", flex: 1 },
        { field: "ClientName", headerName: "Clientname", flex: 1 },
        { field: "sid", headerName: "Sid", flex: 1 },
        {
            field: "ClientDateTime",
            headerName: "Client-Zeitstempel",
            type: "dateTime",
            flex: 1,
            valueGetter: (value) => new Date(value),
        },
        {
            field: "timeStamp",
            headerName: "Server-Zeitstempel",
            type: "dateTime",
            flex: 1,
            valueGetter: (value) => new Date(value),
        },
        { field: "version", headerName: "Version", flex: 1 },
        { field: "clientIp", headerName: "Client-Ip", flex: 1 },
    ];

    useEffect(() => {
        loadUsageLogs(paginationModel);
    }, [paginationModel]);

    const loadUsageLogs = async (model) => {
        setLoading(true);
        try {
            const response = await dispatch(
                getUsageLogs(
                    {
                        skip: model.page * model.pageSize,
                        take: model.pageSize,
                    },
                    identity.user.resellerId
                )
            );
            setRows(response.data);
            setRowCount(response.totalCount);
        } catch (error) {
            console.error("Error loading usage logs:", error);
        }
        setLoading(false);
    };

    return (
        <Box className="audit" sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                UsageLogs
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.UsageLogId}
                rowCount={rowCount}
                loading={loading}
                pageSizeOptions={[20, 50, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={(newModel) => {
                    setPaginationModel(newModel);
                    loadUsageLogs(newModel);
                }}
                autoHeight
                disableRowSelectionOnClick
                sx={{ mt: 2 }}
            />
        </Box>
    );
};

export default UsageLogs;
