import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import "./Content.scss";
import Navigation from "./navigation/Navigation";

const Content = (props) => {
    return (
        <div id="main" className={window.location.pathname === "/" ? "home" : ""}>
            {props.identity && props.identity.user && props.identity.user.Username && <Navigation />}
            <div id="content" className="content">
                <Outlet />
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    const { navigation, identity } = state;
    return {
        navigation,
        identity,
    };
}

export default connect(mapStateToProps)(Content);
