import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { Component } from "react";
import { deleteServerUrl, getServerUrls, insertServerUrl, updateServerUrl } from "../../../actions/serverUrlActions";
import { getWebShopTypes } from "../../../actions/webShopActions";
import store from "../../../store";
import "./ServerUrls.scss";

class ServerUrls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverUrls: [],
            webShopTypes: [],
            searchQuery: "",
            editingRow: null,
            isAddDialogOpen: false,
            newRow: {
                webShopType: "",
                url: "",
            },
            isDeleteDialogOpen: false,
            rowToDelete: null,
        };
    }

    componentDidMount() {
        store.dispatch(getWebShopTypes()).then((json) => {
            this.setState({ webShopTypes: json });
        });
        this.loadServerUrls();
    }

    loadServerUrls = () => {
        store.dispatch(getServerUrls()).then((json) => {
            this.setState({ serverUrls: json });
        });
    };

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    handleEdit = (row) => {
        this.setState({ editingRow: { ...row } });
    };

    handleSave = async (row) => {
        await store.dispatch(updateServerUrl(row));
        this.setState({ editingRow: null });
        this.loadServerUrls();
    };

    handleDelete = async (row) => {
        this.setState({ isDeleteDialogOpen: true, rowToDelete: row });
    };

    confirmDelete = async () => {
        await store.dispatch(deleteServerUrl(this.state.rowToDelete));
        this.setState({ isDeleteDialogOpen: false, rowToDelete: null });
        this.loadServerUrls();
    };

    handleAdd = async () => {
        await store.dispatch(insertServerUrl(this.state.newRow));
        this.setState({
            isAddDialogOpen: false,
            newRow: { webShopType: "", url: "" },
        });
        this.loadServerUrls();
    };

    render() {
        const filteredUrls = this.state.serverUrls.filter(
            (url) =>
                url.url.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                this.state.webShopTypes
                    .find((t) => t.Key === url.webShopType)
                    ?.Value.toLowerCase()
                    .includes(this.state.searchQuery.toLowerCase())
        );

        return (
            <div className="audit">
                <h1>Server-Urls</h1>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                    <TextField
                        placeholder="Suche..."
                        value={this.state.searchQuery}
                        onChange={this.handleSearchChange}
                        variant="outlined"
                        size="small"
                    />
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => this.setState({ isAddDialogOpen: true })}
                    >
                        Neu
                    </Button>
                </div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Webshop-Typ</TableCell>
                                <TableCell>Url</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUrls.map((row) => (
                                <TableRow key={row.serverUrlId}>
                                    <TableCell>{row.serverUrlId}</TableCell>
                                    <TableCell>
                                        {this.state.editingRow?.serverUrlId === row.serverUrlId ? (
                                            <Select
                                                value={this.state.editingRow.webShopType}
                                                onChange={(e) =>
                                                    this.setState({
                                                        editingRow: {
                                                            ...this.state.editingRow,
                                                            webShopType: e.target.value,
                                                        },
                                                    })
                                                }
                                                fullWidth
                                            >
                                                {this.state.webShopTypes.map((type) => (
                                                    <MenuItem key={type.Key} value={type.Key}>
                                                        {type.Value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ) : (
                                            this.state.webShopTypes.find((t) => t.Key === row.webShopType)?.Value
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {this.state.editingRow?.serverUrlId === row.serverUrlId ? (
                                            <TextField
                                                value={this.state.editingRow.url}
                                                onChange={(e) =>
                                                    this.setState({
                                                        editingRow: { ...this.state.editingRow, url: e.target.value },
                                                    })
                                                }
                                                fullWidth
                                            />
                                        ) : (
                                            row.url
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {this.state.editingRow?.serverUrlId === row.serverUrlId ? (
                                            <Button onClick={() => this.handleSave(this.state.editingRow)}>
                                                Speichern
                                            </Button>
                                        ) : (
                                            <>
                                                <IconButton onClick={() => this.handleEdit(row)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => this.handleDelete(row)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Add Dialog */}
                <Dialog open={this.state.isAddDialogOpen} onClose={() => this.setState({ isAddDialogOpen: false })}>
                    <DialogTitle>Neue Server-URL hinzufügen</DialogTitle>
                    <DialogContent>
                        <Select
                            value={this.state.newRow.webShopType}
                            onChange={(e) =>
                                this.setState({
                                    newRow: { ...this.state.newRow, webShopType: e.target.value },
                                })
                            }
                            fullWidth
                            style={{ marginTop: "1rem" }}
                        >
                            {this.state.webShopTypes.map((type) => (
                                <MenuItem key={type.Key} value={type.Key}>
                                    {type.Value}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            value={this.state.newRow.url}
                            onChange={(e) =>
                                this.setState({
                                    newRow: { ...this.state.newRow, url: e.target.value },
                                })
                            }
                            fullWidth
                            label="URL"
                            style={{ marginTop: "1rem" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isAddDialogOpen: false })}>Abbrechen</Button>
                        <Button onClick={this.handleAdd} variant="contained">
                            Hinzufügen
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={this.state.isDeleteDialogOpen}
                    onClose={() => this.setState({ isDeleteDialogOpen: false })}
                >
                    <DialogTitle>Löschen bestätigen</DialogTitle>
                    <DialogContent>Soll dieser Eintrag wirklich gelöscht werden?</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isDeleteDialogOpen: false })}>Abbrechen</Button>
                        <Button onClick={this.confirmDelete} variant="contained" color="error">
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ServerUrls;
