import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { register } from "../../../../actions/identityActions";
import store from "../../../../store";

class PopupAddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                username: "",
                password: generatePassword(),
                firstName: "",
                lastName: "",
                phone: "",
                isLoading: false,
                showPassword: false,
            },
            errors: [],
            userLoggedIn: this.props.identity.user,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        const { user, userLoggedIn } = this.state;
        if (user.username && user.password) {
            store.dispatch(register(userLoggedIn, user, this.props.resellerId)).then((response) => {
                if (!response.Succeeded) {
                    alert("Anlegen des Benutzers fehlgeschlagen.");
                    this.setState({
                        errors: response.errors,
                        isLoading: false,
                    });
                } else {
                    alert("Benutzer anlegen erfolgreich.");
                    this.props.reloadUsers();
                    this.props.hide();
                    this.setState({
                        errors: [],
                        isLoading: false,
                    });
                }
            });
        }
    }

    render() {
        const { user, errors, isLoading } = this.state;

        return (
            <Dialog open={this.props.showAddPopup} onClose={this.props.hide} maxWidth="sm" fullWidth>
                <DialogTitle>Neuen Benutzer anlegen</DialogTitle>
                <DialogContent>
                    {isLoading && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                            <TextField
                                name="username"
                                type="email"
                                label="E-Mail"
                                onChange={this.handleChange}
                                required
                                fullWidth
                            />

                            <TextField
                                name="password"
                                type={this.state.user.showPassword ? "text" : "password"}
                                label="Passwort"
                                value={this.state.user.password}
                                onChange={this.handleChange}
                                required
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() =>
                                                this.setState((prev) => ({
                                                    user: { ...prev.user, showPassword: !prev.user.showPassword },
                                                }))
                                            }
                                        >
                                            {this.state.user.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    ),
                                }}
                            />

                            <TextField
                                name="firstName"
                                label="Vorname"
                                onChange={this.handleChange}
                                required
                                fullWidth
                            />

                            <TextField
                                name="lastName"
                                label="Nachname"
                                onChange={this.handleChange}
                                required
                                fullWidth
                            />

                            <TextField
                                name="phone"
                                label="Telefonnummer"
                                onChange={this.handleChange}
                                required
                                fullWidth
                            />

                            {!errors ||
                                (errors.length === 0 && (
                                    <Box sx={{ fontSize: 11, mb: 1 }}>
                                        <span>{"Passwords must be at least 6 characters."}</span>
                                        <br />
                                        <span>{"Passwords must have at least one non alphanumeric character."}</span>
                                        <br />
                                        <span>{"Passwords must have at least one digit ('0'-'9')."}</span>
                                        <br />
                                        <span>{"Passwords must have at least one uppercase ('A'-'Z')."}</span>
                                        <br />
                                        <span>{"Passwords must have at least one lowercase ('a'-'z')."}</span>
                                    </Box>
                                ))}

                            {errors &&
                                errors.length > 0 &&
                                errors.map((error, index) => (
                                    <Box key={index} sx={{ color: "error.main" }}>
                                        {error.description}
                                    </Box>
                                ))}

                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                <Button type="submit" variant="contained">
                                    Speichern
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

function generatePassword() {
    var length = 32,
        charsetLower = "abcdefghijklmnopqrstuvwxyz",
        charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        charsetNumeric = "0123456789",
        charsetAlphaNumeric = "!?-_()",
        retVal = "";
    for (var i = 0, n = charsetLower.length; i < length / 4; ++i) {
        retVal += charsetLower.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetUpper.length; i < length / 4; ++i) {
        retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetNumeric.length; i < length / 4; ++i) {
        retVal += charsetNumeric.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetAlphaNumeric.length; i < length / 4; ++i) {
        retVal += charsetAlphaNumeric.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(PopupAddUser);
