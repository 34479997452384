import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();


export function getServerUrls() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/serverUrls/getServerUrls", requestOptions)
            .then(response => handleResponse(response))
            .then(json => { return json });
    };
}

export function updateServerUrl(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/serverUrls/updateServerUrl", requestOptions)
            .then(response => handleResponse(response))
    };
}

export function deleteServerUrl(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/serverUrls/deleteServerUrl", requestOptions)
            .then(response => handleResponse(response))
    };
}

export function insertServerUrl(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/serverUrls/insertServerUrl", requestOptions)
            .then(response => handleResponse(response))
    };
}