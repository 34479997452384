import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DnsIcon from "@mui/icons-material/Dns";
import HomeIcon from "@mui/icons-material/Home";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuIcon from "@mui/icons-material/Menu";
import StoreIcon from "@mui/icons-material/Store";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Navigation.scss";

const Navigation = (props) => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const navigate = useNavigate();

    const goToPage = (path) => {
        navigate(path);
    };

    const navigationItems = [
        {
            id: 1,
            text: "Navigation",
            icon: <MenuIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                setNavigationOpen(!navigationOpen);
            },
        },
        {
            id: 2,
            text: "Zurück",
            icon: <ArrowBackIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                navigate(-1);
            },
        },
        {
            id: 3,
            text: "Start",
            icon: <HomeIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/");
            },
        },
        {
            id: 4,
            text: "Lizenzen",
            icon: <VpnKeyIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/lizenzen");
            },
        },
        {
            id: 5,
            text: "Reseller",
            icon: <StoreIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/resellers");
            },
        },
        {
            id: 6,
            text: "Audit",
            icon: <AssignmentIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/audit");
            },
        },
        {
            id: 7,
            text: "UsageLogs",
            icon: <LockOpenIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/usageLogs");
            },
        },
        {
            id: 8,
            text: "Server-Urls",
            icon: <DnsIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/serverUrls");
            },
        },
        {
            id: 10,
            text: "Module",
            icon: <AccountTreeIcon fontSize={"large"} sx={{ color: "black" }} />,
            link: () => {
                goToPage("/modules");
            },
        },
    ];

    return (
        <React.Fragment>
            {props.identity.user && (
                <div id="navigation" className={navigationOpen ? "open" : "closed"}>
                    <List>
                        {navigationItems.map((item) => (
                            <ListItem key={item.id} onClick={item.link} button title={item.text}>
                                <ListItemIcon color="black">{item.icon}</ListItemIcon>
                                {navigationOpen && <ListItemText primary={item.text} />}
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Navigation);
