import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLicenseById, startInstance, stopInstance } from "../../../../actions/licensesActions";
import { getServerUrls } from "../../../../actions/serverUrlActions";
import { createShop, deployShop, getAvailableShopVersions, updateShop } from "../../../../actions/shopActions";
import "./WebShops.scss";

const WebShops = ({ licenseId }) => {
    const [license, setLicense] = useState(null);
    const [availableVersions, setAvailableVersions] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const licenseData = await dispatch(getLicenseById(licenseId));
            setLicense(licenseData);

            const mensaVersions = await dispatch(getAvailableShopVersions("mensashop"));
            const ggbiosVersions = await dispatch(getAvailableShopVersions("ggbiosshop"));
            setAvailableVersions({
                mensashop: mensaVersions,
                ggbiosshop: ggbiosVersions,
            });

            await dispatch(getServerUrls());
        };

        fetchData();
    }, [dispatch, licenseId]);

    const handleCreate = async (newRow) => {
        const updatedRow = { ...newRow, licenseId };
        await dispatch(createShop(updatedRow));
        const updatedLicense = await dispatch(getLicenseById(licenseId));
        setLicense(updatedLicense);
    };

    const handleUpdate = async (updatedRow) => {
        await dispatch(updateShop(updatedRow));
        const updatedLicense = await dispatch(getLicenseById(licenseId));
        setLicense(updatedLicense);
    };

    const handleDeploy = async (shopId) => {
        await dispatch(deployShop(licenseId, shopId));
        const updatedLicense = await dispatch(getLicenseById(licenseId));
        setLicense(updatedLicense);
    };

    const handleStartShop = (shopKind, url) => {
        dispatch(startInstance(shopKind, url.replaceAll(".", "-")));
    };

    const handleStopShop = (shopKind, url) => {
        dispatch(stopInstance(shopKind, url.replaceAll(".", "-")));
    };

    const columns = [
        { field: "shopName", headerName: "Name", flex: 1, editable: true, width: 100 },
        { field: "url", headerName: "ShopUrl", flex: 1, editable: true },
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            editable: true,
            renderCell: (params) => {
                const currentVersion = params.value
                    ? params.value.replace(`foodgenius.azurecr.io/bios/${params.row.shopKind}:`, "")
                    : "";

                return (
                    <select
                        value={currentVersion}
                        onChange={(e) => {
                            const newValue = `foodgenius.azurecr.io/bios/${params.row.shopKind}:${e.target.value}`;
                            handleUpdate({ ...params.row, image: newValue });
                        }}
                    >
                        {availableVersions[params.row.shopKind]?.map((version) => (
                            <option key={version} value={version}>
                                {version}
                            </option>
                        ))}
                    </select>
                );
            },
        },
        { field: "deployStatus", headerName: "Deploy Status", flex: 1 },
        { field: "keycloakRealmName", headerName: "Keycloak Realm", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Button
                        onClick={() => handleDeploy(params.row.shopId)}
                        variant="contained"
                        size="small"
                        sx={{ mr: 1 }}
                    >
                        Deploy
                    </Button>
                    <Button
                        onClick={() => handleStartShop(params.row.shopKind, params.row.url)}
                        variant="contained"
                        size="small"
                        sx={{ mr: 1 }}
                    >
                        Starten
                    </Button>
                    <Button
                        onClick={() => handleStopShop(params.row.shopKind, params.row.url)}
                        variant="contained"
                        color="error"
                        size="small"
                    >
                        Stoppen
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box className="webshops" sx={{ height: "80vh", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                <Typography variant="h4">Webshops</Typography>
                <Button variant="contained" color="primary" onClick={() => handleCreate({ shopKind: "ggbiosshop" })}>
                    Add Online Shop
                </Button>
            </Box>
            {license && license.Shops && (
                <DataGrid
                    rows={license.Shops}
                    columns={columns}
                    getRowId={(row) => row.shopId}
                    disableSelectionOnClick
                    autoHeight
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    editMode="cell"
                    onRowEditStop={(params, event) => {
                        if (params.reason === "rowFocusOut") {
                            handleUpdate(params.row);
                        }
                    }}
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "background.paper",
                            borderBottom: "1px solid",
                            borderColor: "divider",
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default WebShops;
