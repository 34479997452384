import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRouteAdmin = ({ children }) => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user?.Id || !user?.Username || !user?.Token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};
