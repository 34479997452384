import React from "react";
import Content from "./content/Content";
import Header from "./header/Header";

const MainLayout = () => {
    return (
        <>
            <Header />
            <Content />
        </>
    );
};

export default MainLayout;
