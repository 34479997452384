import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    addInstanceToSyncTargets,
    getAllLicensesByResellerId,
    getSyncTargets,
    removeInstanceFromSyncTargets,
} from "../../../../actions/licensesActions";
import store from "../../../../store";

class Sync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseId: this.props.licenseId,
            syncTargets: [],
            licenses: [],
            targetLicenseToAdd: null,
            page: 0,
            rowsPerPage: 15,
            isAddDialogOpen: false,
            error: null,
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        store
            .dispatch(getSyncTargets(this.state.licenseId))
            .then((response) => {
                this.setState({ syncTargets: response || [], error: null });
            })
            .catch((error) => {
                console.error("Failed to load sync targets:", error);
                this.setState({ syncTargets: [], error: "Failed to load sync targets" });
            });

        store
            .dispatch(getAllLicensesByResellerId(this.props.identity.user.resellerId))
            .then((json) => {
                this.setState({ licenses: json || [] });
            })
            .catch((error) => {
                console.error("Failed to load licenses:", error);
                this.setState({ licenses: [] });
            });
    }

    handleCreate() {
        if (!this.state.targetLicenseToAdd) {
            return;
        }

        store
            .dispatch(addInstanceToSyncTargets(this.state.licenseId, this.state.targetLicenseToAdd.LicenseId))
            .then(() => {
                this.setState({ targetLicenseToAdd: null, isAddDialogOpen: false });
                this.loadData();
            })
            .catch((error) => {
                console.error("Failed to add sync target:", error);
            });
    }

    handleRemove(targetUrl) {
        let targetLicense = this.state.licenses.filter((license) => targetUrl.includes(license.deployFrontendUrl))[0];
        if (!targetLicense) {
            return;
        }

        store
            .dispatch(removeInstanceFromSyncTargets(this.state.licenseId, targetLicense.LicenseId))
            .then(() => {
                this.loadData();
            })
            .catch((error) => {
                console.error("Failed to remove sync target:", error);
            });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    render() {
        const { syncTargets, page, rowsPerPage, error } = this.state;

        return (
            <Box sx={{ width: "100%", p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Syncziele dieser Instanz
                </Typography>

                {error && (
                    <Typography color="error" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}

                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>URL</TableCell>
                                    <TableCell align="right">Aktionen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {syncTargets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.url}</TableCell>
                                        <TableCell align="right">
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => this.handleRemove(row.url)}
                                            >
                                                Löschen
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[15, 50, 100]}
                        component="div"
                        count={syncTargets.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>

                <Button variant="contained" onClick={() => this.setState({ isAddDialogOpen: true })}>
                    Hinzufügen
                </Button>

                <Dialog open={this.state.isAddDialogOpen} onClose={() => this.setState({ isAddDialogOpen: false })}>
                    <DialogTitle>Syncziel hinzufügen</DialogTitle>
                    <DialogContent>
                        <Select
                            fullWidth
                            value={this.state.targetLicenseToAdd?.LicenseId || ""}
                            onChange={(e) => {
                                const selectedLicense = this.state.licenses.find(
                                    (license) => license.LicenseId === e.target.value
                                );
                                this.setState({ targetLicenseToAdd: selectedLicense });
                            }}
                            sx={{ mt: 2 }}
                        >
                            {this.state.licenses.map((license) => (
                                <MenuItem key={license.LicenseId} value={license.LicenseId}>
                                    {license.deployFrontendUrl}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isAddDialogOpen: false })}>Abbrechen</Button>
                        <Button onClick={this.handleCreate} variant="contained">
                            Hinzufügen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Sync);
