import { library } from "@fortawesome/fontawesome-svg-core";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import MainLayout from "./components/layout/MainLayout";
import Licenses from "./components/pages/licenses/Licenses";
import Registration from "./components/pages/registration/Registration";
// Import other components for routes
import { PrivateRouteAdmin } from "./components/core/privateRoute/PrivateRouteAdmin";
import Audit from "./components/pages/audit/Audit";
import Dashboard from "./components/pages/dashboard/Dashboard";
import LicenseTabsManagement from "./components/pages/licenses/LicenseTabManagement";
import Webshop from "./components/pages/licenses/webShops/WebShop";
import Login from "./components/pages/login/Login";
import Modules from "./components/pages/modules/Modules";
import Orchestration from "./components/pages/orchestration/Orchestration";
import Resellers from "./components/pages/resellers/Resellers";
import Users from "./components/pages/resellers/users/Users";
import ServerUrls from "./components/pages/serverUrls/ServerUrls";
import UsageLogs from "./components/pages/usageLogs/UsageLogs";

library.add(faUndo);

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="registration"
                        element={
                            <PrivateRouteAdmin>
                                <Registration />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="lizenzen"
                        element={
                            <PrivateRouteAdmin>
                                <Licenses />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="resellers"
                        element={
                            <PrivateRouteAdmin>
                                <Resellers />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="audit"
                        element={
                            <PrivateRouteAdmin>
                                <Audit />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="usageLogs"
                        element={
                            <PrivateRouteAdmin>
                                <UsageLogs />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="serverUrls"
                        element={
                            <PrivateRouteAdmin>
                                <ServerUrls />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="orchestration"
                        element={
                            <PrivateRouteAdmin>
                                <Orchestration />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="modules"
                        element={
                            <PrivateRouteAdmin>
                                <Modules />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="lizenzen/:id"
                        element={
                            <PrivateRouteAdmin>
                                <LicenseTabsManagement />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="webShop/:id"
                        element={
                            <PrivateRouteAdmin>
                                <Webshop />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="resellers/:id"
                        element={
                            <PrivateRouteAdmin>
                                <Users />
                            </PrivateRouteAdmin>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRouteAdmin>
                                <Dashboard />
                            </PrivateRouteAdmin>
                        }
                    />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
