import React, { Component } from "react";
import "./Confirmation.scss";

export default class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = { paymentConfirmed: false };
    }

    render() {
        return (
            <div className={"confirmation"}>
                <div className={"confirmationBox"}>
                    <h2>Bestätigung</h2>
                    <div>
                        <p>
                            {this.props.payedByParent
                                ? "Ihr Unternehmen hat bereits für Ihre Lizenz bezahlt, sie erhalten einen Link zu Ihrer BIOS Instanz per E-Mail sobald die Einrichtung abgeschlossen ist. Dies kann einige Minuten dauern."
                                : "Ihre Zahlung wurde verarbeitet, sie erhalten einen Link zu Ihrer BIOS Instanz per E-Mail sobald die Einrichtung abgeschlossen ist. Dies kann einige Minuten dauern."}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
