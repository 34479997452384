import { Button, CircularProgress, TextField } from "@mui/material";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { checkPayment, deployWithoutToken, registerNewLicense, setupBios } from "../../../../actions/licensesActions";
import { sendVerificationMail } from "../../../../actions/userActions";
import store from "../../../../store";
import Confirmation from "../../confirmation/Confirmation";

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            paymentFailed: false,
            showPaymentForm: false,
            payedByParent: false,
            showErrorMessage: false,
            storeNameUnavailable: false,
            loading: false,
            verificationCode: "",
            verificationCodeCheck: "",
            passwordConfirmation: "",
            allowSendCode: true,
            license: {
                email: "",
                password: "",
                ownerFirstName: "",
                ownerLastName: "",
                City: "",
                Street: "",
                zip: "",
                AddressDetails: "",
                houseNumber: "",
                companyKey: "",
                paymentConfirmed: "false",
                title: "",
                StoreName: "",
                ModulesBought: [],
            },
            errors: {},
            touched: {},
        };

        this.handleNextStep = this.handleNextStep.bind(this);
        this.passwordComparison = this.passwordComparison.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.registerLicense = this.registerLicense.bind(this);
        this.handleDeployForPaidLicense = this.handleDeployForPaidLicense.bind(this);
        this.handlePreviousStep = this.handlePreviousStep.bind(this);
    }

    componentDidMount() {
        let _t = Math.round(new Date().getTime() / 1000);
        let novalnetSDKUrl = "https://paygate.novalnet.de/v2/checkout.js?t=" + _t;
        const script = document.createElement("script");
        script.src = novalnetSDKUrl;
        script.async = true;
        script.id = "novalnet-checkout-js";
        document.body.appendChild(script);
        script.addEventListener("load", this.handleScriptLoaded);
        script.addEventListener("error", this.handleScriptLoadError);
        window.React = React;
        window.ReactDOM = ReactDOM;
        let params = new URLSearchParams(window.location.search);
        var license = JSON.parse(localStorage.getItem("license"));
        var step = JSON.parse(localStorage.getItem("step"));
        if (license) this.setState({ license: license, step: step ? step : 1 });
        if (params.get("tid") != null && license && params.get("status") !== "FAILURE") {
            store.dispatch(registerNewLicense(license)).then((response) => {
                store
                    .dispatch(
                        checkPayment(
                            params.get("tid"),
                            response.LicenseId,
                            license.ownerFirstName,
                            license.ownerLastName,
                            license.email,
                            license.password
                        )
                    )
                    .then((response) => {
                        if (params.get("status") === "FAILURE") {
                            this.setState({ paymentFailed: true });
                        } else if (response.isPaidFor) {
                            localStorage.removeItem("license");
                            this.setState({ paymentConfirmed: true });
                        }
                    });
            });
        }
    }

    togglePaymentForm() {
        this.setState({ showPaymentForm: !this.state.showPaymentForm });
    }

    componentWillUnmount() {
        let element = document.getElementById("novalnet-checkout-js");
        let elementParent = document.getElementById("novalnet-checkout-js").parentNode;
        elementParent.removeChild(element);
    }

    handleChange(e, fieldName = null) {
        const name = fieldName || e.target.name;
        const value = e.target.value;
        const { license } = this.state;
        this.setState({
            license: {
                ...license,
                [name]: value,
            },
            touched: {
                ...this.state.touched,
                [name]: true,
            },
        });
    }

    passwordComparison() {
        return this.state.license.password;
    }

    validate() {
        const errors = {};
        const { license, passwordConfirmation } = this.state;

        if (!license.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(license.email)) {
            errors.email = "Invalid email format";
        }

        if (!license.password) {
            errors.password = "Password is required";
        } else if (
            !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/.test(license.password)
        ) {
            errors.password =
                "Password must be at least 6 characters with uppercase, lowercase, number and special character";
        }

        if (passwordConfirmation !== license.password) {
            errors.passwordConfirmation = "Passwords don't match";
        }

        return errors;
    }

    handleNextStep(e) {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors });

        if (Object.keys(errors).length === 0) {
            localStorage.setItem("license", JSON.stringify(this.state.license));
            if (e.validationGroup === "Modules") {
                if (this.state.license.ModulesBought.length > 0) {
                    this.setState({ step: this.state.step + 1, loading: false }, () =>
                        localStorage.setItem("step", JSON.stringify(this.state.step))
                    );
                }
            } else {
                this.setState({ step: this.state.step + 1, loading: false }, () =>
                    localStorage.setItem("step", JSON.stringify(this.state.step))
                );
            }
        }
    }

    handlePreviousStep() {
        this.setState({ step: this.state.step - 1 }, () =>
            localStorage.setItem("step", JSON.stringify(this.state.step))
        );
    }

    registerLicense() {
        store.dispatch(registerNewLicense(this.state.license));
    }

    handleDeployForPaidLicense() {
        const { license } = this.state;
        store.dispatch(registerNewLicense(license)).then((response) => {
            store
                .dispatch(
                    checkPayment(
                        "",
                        response.LicenseId,
                        license.ownerFirstName,
                        license.ownerLastName,
                        license.email,
                        license.password
                    )
                )
                .then((response) => {
                    if (response.isPaidFor) {
                        this.setState({ paymentConfirmed: true, payedByParent: true, loading: false });
                        store.dispatch(deployWithoutToken(response.LicenseNumber)).then((deployResponse) => {
                            if (deployResponse.status === 200)
                                store.dispatch(
                                    setupBios(
                                        response.LicenseNumber,
                                        this.state.license.ownerFirstName,
                                        this.state.license.ownerLastName,
                                        this.state.license.email,
                                        this.state.license.password
                                    ),
                                    () => localStorage.removeItem("license")
                                );
                        });
                    }
                });
        });
    }

    render() {
        const { errors, touched } = this.state;

        return (
            <React.Fragment>
                {this.state.loading && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
                )}

                {!this.state.loading && (
                    <React.Fragment>
                        {this.state.paymentFailed && !this.state.showPaymentForm && (
                            <React.Fragment>
                                <h2>Abonnieren fehlgeschlagen</h2>
                                <div>
                                    <p>
                                        Ihre Zahlung war nicht erfolgreich, bitte versuchen Sie es erneut oder wenden
                                        sie sich an den Support
                                    </p>
                                </div>
                            </React.Fragment>
                        )}
                        {!this.state.paymentConfirmed && !this.state.showPaymentForm && (
                            <form onSubmit={this.handleSubmit}>
                                <h5>{"Schritt " + this.state.step + " von 6"}</h5>

                                {this.state.step === 1 && (
                                    <React.Fragment>
                                        <h4>Bitte gib deine E-Mail Adresse an</h4>

                                        <TextField
                                            fullWidth
                                            name="email"
                                            type="email"
                                            label="E-Mail-Adresse"
                                            value={this.state.license.email}
                                            onChange={this.handleChange}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                            margin="normal"
                                        />

                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                disabled={!this.state.allowSendCode}
                                                variant="contained"
                                                style={{ marginBottom: 10 }}
                                                onClick={() => {
                                                    if (!errors.email) {
                                                        this.setState({ allowSendCode: false });
                                                        store
                                                            .dispatch(sendVerificationMail(this.state.license.email))
                                                            .then((response) => {
                                                                this.setState({ verificationCodeCheck: response });
                                                            });
                                                        setTimeout(() => this.setState({ allowSendCode: true }), 3000);
                                                    }
                                                }}
                                            >
                                                {this.state.verificationCodeCheck === ""
                                                    ? "Code senden"
                                                    : "Code erneut senden"}
                                            </Button>
                                        </div>

                                        <TextField
                                            fullWidth
                                            name="code"
                                            label="Verifizierungscode eingeben"
                                            value={this.state.verificationCode}
                                            onChange={(e) => {
                                                this.setState({ verificationCode: e.target.value });
                                            }}
                                            error={
                                                this.state.verificationCode !==
                                                this.state.verificationCodeCheck.toString()
                                            }
                                            helperText={
                                                this.state.verificationCode !==
                                                this.state.verificationCodeCheck.toString()
                                                    ? "Code ist nicht korrekt"
                                                    : ""
                                            }
                                            margin="normal"
                                        />

                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                                            <Button
                                                variant="outlined"
                                                disabled={this.state.step === 1}
                                                onClick={this.handlePreviousStep}
                                                style={{ marginRight: 10 }}
                                            >
                                                Zurück
                                            </Button>

                                            <Button
                                                variant="contained"
                                                disabled={
                                                    !(
                                                        this.state.verificationCodeCheck.toString() ===
                                                            this.state.verificationCode &&
                                                        this.state.verificationCodeCheck !== ""
                                                    )
                                                }
                                                onClick={this.handleNextStep}
                                            >
                                                Weiter
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}

                                {/* Continue with similar conversions for steps 2-6 */}
                            </form>
                        )}
                        {this.state.paymentConfirmed && !this.state.paymentFailed && (
                            <Confirmation payedByParent={this.state.payedByParent} />
                        )}
                        {this.state.showPaymentForm && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{ width: 1300, position: "absolute", top: 50 }}
                                    id="novalnet_payment_form"
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default RegistrationForm;
