import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { getAllModules, updateModule } from "../../../actions/moduleActions";
import store from "../../../store";

export default class modules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: undefined,
        };
        this.handleInit = this.handleInit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleInit() {
        store.dispatch(getAllModules()).then((json) => {
            const formattedData = json.map((module) => ({
                id: module.moduleId,
                ...module,
            }));
            this.setState({ modules: formattedData });
        });
    }

    handleUpdate(updatedRow) {
        store.dispatch(updateModule(updatedRow)).then(() => {
            this.handleInit();
        });
    }

    componentDidMount() {
        this.handleInit();
    }

    render() {
        const columns = [
            { field: "name", headerName: "Name", width: 160, editable: true },
            { field: "price", headerName: "Preis", width: 160, editable: true },
        ];

        return (
            <React.Fragment>
                <Typography variant="h4" sx={{ marginLeft: 2.5, marginBottom: 2 }}>
                    Module
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "auto",
                    }}
                >
                    <Box sx={{ width: 800 }}>
                        <DataGrid
                            rows={this.state.modules || []}
                            columns={columns}
                            getRowId={(row) => row.moduleId}
                            autoHeight
                            disableColumnMenu
                            processRowUpdate={(newRow, oldRow) => {
                                this.handleUpdate(newRow);
                                return newRow;
                            }}
                            onProcessRowUpdateError={(error) => {
                                console.error("Error updating row:", error);
                            }}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}
