import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAuditByResellerId(loadOptions, resellerId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId)
    };

    let query = new URLSearchParams(loadOptions).toString();

    return function (dispatch) {
        return fetch(config.backendHost + "/audit/getAuditByResellerId?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}
