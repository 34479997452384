import { combineReducers } from "redux";
import { users } from "./usersReducer";
import identity from "./identityReducer";

const appReducer = combineReducers({
    users,
    identity,
});

const rootReducer = (state, action) => {
    if (action.type === "APP_RESET") {
        state = undefined;
        window.location.reload(); // TODO: rerender App
    }

    return appReducer(state, action);
};

export default rootReducer;
