import Config from "../Config";
import { handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllModules() {
    const requestOptions = {
        method: "POST",
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Modules/getModules", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function insertModule(data) {
    const requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Modules/insert", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateModule(data) {
    const requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Modules/update", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function deleteModule(data) {
    const requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Modules/delete", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}
