import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuditByResellerId } from "../../../actions/auditActions";
import "./Audit.scss";

const Audit = () => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const dispatch = useDispatch();
    const identity = useSelector((state) => state.identity);

    const columns = [
        { field: "name", headerName: "Name", flex: 1 },
        { field: "tableName", headerName: "Tabelle", flex: 1 },
        { field: "type", headerName: "Typ", flex: 1 },
        { field: "propertyName", headerName: "Eigenschaft", flex: 1 },
        { field: "oldValue", headerName: "alter Wert", flex: 1 },
        { field: "newValue", headerName: "neuer Wert", flex: 1 },
        {
            field: "timeStamp",
            headerName: "Datum",
            type: "dateTime",
            flex: 1,
            valueGetter: (value) => new Date(value),
        },
        { field: "User.UserName", headerName: "Benutzer", flex: 1 },
    ];

    // Use useEffect to load data on component mount and when paginationModel changes
    useEffect(() => {
        loadAuditData(paginationModel);
    }, [paginationModel]); // Dependency array includes paginationModel

    const loadAuditData = async (model) => {
        setLoading(true);
        try {
            const response = await dispatch(
                getAuditByResellerId(
                    {
                        skip: model.page * model.pageSize,
                        take: model.pageSize,
                    },
                    identity.user.resellerId
                )
            );
            setRows(response.data);
            setRowCount(response.totalCount);
        } catch (error) {
            console.error("Error loading audit data:", error);
        }
        setLoading(false);
    };

    return (
        <Box className="audit" sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Audit
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.Id}
                rowCount={rowCount}
                loading={loading}
                pageSizeOptions={[15, 20, 50, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={(newModel) => {
                    setPaginationModel(newModel);
                    loadAuditData(newModel);
                }}
                autoHeight
                disableRowSelectionOnClick
                sx={{ mt: 2 }}
            />
        </Box>
    );
};

export default Audit;
