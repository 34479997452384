import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getContainerInstances() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/container/getContainerInstances", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function startContainerInstance(containerGroupName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(containerGroupName),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/container/startContainer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function restartContainerInstance(containerGroupName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(containerGroupName),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/container/restartContainer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function stopContainerInstance(containerGroupName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(containerGroupName),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/container/stopContainer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}
