import { Box, Button, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../../../actions/identityActions";
import store from "../../../../store";
import "./LoginForm.scss";

const LoginForm = (props) => {
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        username: "",
        password: "",
        snackbarOpen: false,
        snackbarMessage: "",
    });

    useEffect(() => {
        redirectLoggedInUser();
    }, [props.identity.user.Token]);

    const redirectLoggedInUser = () => {
        if (props.identity.user.Token) {
            navigate("/");
        }
    };

    const handleEmailChange = (e) => {
        setFormState({
            ...formState,
            username: e.target.value,
        });
    };

    const handlePasswordChange = (e) => {
        setFormState({
            ...formState,
            password: e.target.value,
        });
    };

    const handleSnackbarClose = () => {
        setFormState({
            ...formState,
            snackbarOpen: false,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { username, password } = formState;
        if (username && password) {
            store.dispatch(login(username, password)).then((response) => {
                if (response.user.status === 401) {
                    setFormState({
                        ...formState,
                        snackbarOpen: true,
                        snackbarMessage: response.user.message,
                    });
                }
            });
        }
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                    <TextField
                        name="username"
                        type="email"
                        placeholder="E-Mail-Adresse"
                        required
                        onChange={handleEmailChange}
                        fullWidth
                        error={!formState.username && formState.username !== ""}
                        helperText={!formState.username && formState.username !== "" ? "Dieses Feld wird benötigt" : ""}
                    />
                    <TextField
                        name="password"
                        type="password"
                        placeholder="Passwort"
                        required
                        onChange={handlePasswordChange}
                        fullWidth
                        error={!formState.password && formState.password !== ""}
                        helperText={!formState.password && formState.password !== "" ? "Dieses Feld wird benötigt" : ""}
                    />
                    <Button variant="contained" type="submit" fullWidth>
                        Login
                    </Button>
                </Box>
            </form>
            <Snackbar
                open={formState.snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={formState.snackbarMessage}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { identity } = state;
    return {
        identity,
    };
};

export default connect(mapStateToProps)(LoginForm);
