import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton } from "@mui/material";
import React, { Component } from "react";
import { getContainerInstances } from "../../../actions/containerActions";
import store from "../../../store";
import ContainerInstance from "./ContainerInstance";

export default class Orchestration extends Component {
    constructor() {
        super();

        this.state = {
            containerInstances: [],
            showEditPopup: false,
            showAddPopup: false,
        };

        this.refreshDatagrid = this.refreshDatagrid.bind(this);
    }

    componentDidMount() {
        store.dispatch(getContainerInstances()).then((result) =>
            this.setState({
                containerInstances: result,
            })
        );
    }

    refreshDatagrid() {
        store.dispatch(getContainerInstances()).then((result) =>
            this.setState({
                containerInstances: result,
            })
        );
    }

    render() {
        return (
            <Box sx={{ maxWidth: "1000px", margin: "auto", marginTop: "10px" }}>
                <IconButton sx={{ marginBottom: 1 }} onClick={this.refreshDatagrid}>
                    <RefreshIcon />
                </IconButton>
                <Box sx={{ display: "flex", height: 40 }}>
                    <Box
                        sx={{
                            width: 500,
                            bgcolor: "grey.200",
                            border: 1,
                            borderColor: "grey.300",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            pl: 1,
                        }}
                    >
                        Frontend-Url
                    </Box>
                    <Box
                        sx={{
                            width: 300,
                            bgcolor: "grey.200",
                            border: 1,
                            borderColor: "grey.300",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            pl: 1,
                        }}
                    >
                        Lizenz
                    </Box>
                    <Box
                        sx={{
                            width: 200,
                            bgcolor: "grey.200",
                            border: 1,
                            borderColor: "grey.300",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            pl: 1,
                        }}
                    >
                        Status
                    </Box>
                </Box>
                <Box>
                    {this.state.containerInstances.map((containerInstance) => (
                        <ContainerInstance
                            key={containerInstance[0]?.containerGroupName}
                            containerInstance={containerInstance}
                            refreshDatagrid={this.refreshDatagrid}
                        />
                    ))}
                </Box>
            </Box>
        );
    }
}
